import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SimpleReactLightbox from "simple-react-lightbox"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import Page from "../templates"
import SEO from "../templates/seo"
import Gallery from "../components/Gallery"
import Chip from "../components/elements/Chip"

const LinnEwers = ({ data }) => {
  const [filter, setFilter] = useState([])

  const filterCategory = category => {
    if (filter.includes(category))
      setFilter([...filter].filter(i => i != category))
    else setFilter([...filter].concat([category]))
  }
  return (
    <Page>
      <SEO title="Linn Ewers" />
      <h1 className="text-primary">Linn Ewers</h1>
      <p>Zu meiner Tochter</p>
      <Row>
        <Col xs={12} md={6}>
          <GatsbyImage
            style={{ maxWidth: "500px" }}
            className="mt-4 mb-5"
            loading="auto"
            image={getImage(data.profile.image)}
            alt="Linn Ewers"
          />
        </Col>
        <Col xs={12} md={6} className="mt-4 mb-5">
          <ul>
            <li>
              <p>1986 geboren in Tübingen</p>
            </li>
            <li>
              <p>
                Mit 3. Jahren fange ich bei meiner Mutter Anne Ewers an zu malen
              </p>
            </li>
            <li>
              <p>
                Die Kunst begleitet mich auch sehr in meinen Schulalltag an der
                Waldorfschule in Schloß Hamborn
              </p>
            </li>
            <li>
              <p>
                Ich besuche mehrere Kunstkurse bei Felix Höffner, Schloß
                Hamborn, Andrea Schloen, Worpswede und weiterhin bei meiner
                Mutter Anne Ewers
              </p>
            </li>
            <li>
              <p>
                Die Schulzeit beende ich mit der Abschlussarbeit zum Thema
                "moderne Kunst" und der intensiven 1-jährigen Auseinandersetzung
                mit Ölmalerei
              </p>
            </li>
            <li>
              <p>
                Im Jahr 2006 und 2013 folgt mein Bachelorstudium in
                International Business Studies an der Universität Paderborn und
                mein Masterstudium in International Relations an der University
                of Kent in Brüssel
              </p>
            </li>
            <li>
              <p>
                Tätig bin ich heute in der humanitären Hilfe - meine Kunst
                entsteht teilweise im Atelier von Anne Ewers oder auf meinen
                Reisen um die Welt
              </p>
            </li>

            <li>
              <p>
                Ausstellungen meiner Werke befinden sich derzeit im Galerie
                Hotel und im Restaurant Pan e Vino in Paderborn
              </p>
            </li>
          </ul>
        </Col>
      </Row>
      <h2 className="mt-5 mb-3">Eine Auswahl meiner Arbeiten...</h2>
      <SimpleReactLightbox>
        <div className="scroll-vertically mb-3">
          <Chip
            className="mb-1"
            label="Personen"
            active={filter.includes("people")}
            onClick={() => filterCategory("people")}
          />
          <Chip
            className="mb-1"
            label="Blumen"
            active={filter.includes("flowers")}
            onClick={() => filterCategory("flowers")}
          />
          <Chip
            className="mb-1"
            label="Abstrakt"
            active={filter.includes("abstract")}
            onClick={() => filterCategory("abstract")}
          />
          {filter.length > 0 && (
            <Button
              className="mb-1"
              variant="link"
              size="sm"
              onClick={() => setFilter([])}
            >
              Filter löschen
            </Button>
          )}
        </div>
        <Gallery
          images={data.images.nodes.filter(
            node => filter.length == 0 || filter.includes(node.category)
          )}
        />
      </SimpleReactLightbox>
    </Page>
  )
}

export default LinnEwers

export const pageQuery = graphql`
  query {
    images: allContentfulArtwork(
      filter: { artist: { fistName: { eq: "Linn" } } }
      sort: { order: DESC, fields: year }
    ) {
      nodes {
        id
        title
        material
        height
        width
        year
        category
        image {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          file {
            url
          }
        }
      }
    }
    profile: contentfulArtist(contentful_id: { eq: "12c7U1DL9B4XYJNoK8C7a8" }) {
      fistName
      lastName
      text {
        text
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`
