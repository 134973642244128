import React from "react"
import Button from "react-bootstrap/Button"
import styled from "styled-components"

export default function Chip(props) {
  const { className, label, active, onClick } = props

  return (
    <ChipButton
      className={className}
      style={{ minHeight: "2rem" }}
      active={active}
      onClick={onClick ? onClick : () => {}}
      size="sm"
      outline
    >
      {label && <ChipLabel>{label}</ChipLabel>}
    </ChipButton>
  )
}

Chip.defaultProps = {
  toggle: true,
}

const ChipButton = styled(Button)`
  display: flex !important;
  margin-right: 0.25rem !important;
  align-items: center !important;
  border-radius: 16px !important;
  border-color: #ced4da !important;
  color: #556f7a !important;
  background-color: transparent !important;
  @media (prefers-color-scheme: dark) {
    color: #d9d7e0;
  }
  :hover {
    color: #556f7a !important;
    /* background-color: rgb(244, 67, 54, 0.1) !important; */
    background-color: rgb(98, 151, 167, 0.1) !important;

    border-color: #ced4da !important;
  }
  :focus {
    box-shadow: none !important;
  }
  &.active {
    color: #6297a7 !important;
    /* background-color: rgb(244, 67, 54, 0.1) !important; */
    border-color: #6297a7 !important;
  }
`

const ChipLabel = styled.span`
  display: inline-block;
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;
  padding-left: 6px;
  padding-right: 6px;
  text-overflow: ellipsis;
`
