import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { SRLWrapper, useLightbox } from "simple-react-lightbox"
import Masonry from "react-masonry-css"

export default props => {
  const { openLightbox } = useLightbox()
  const { images } = props

  const imageList = images.map(item => ({
    src: "http:" + item.image.file.url,
    caption:
      item.title +
      ": " +
      item.material +
      " " +
      item.height +
      "x" +
      item.width +
      ", " +
      item.year,
  }))

  const options = {
    settings: { autoplaySpeed: 1500, transitionSpeed: 900 },
    thumbnails: { showThumbnails: false },
    caption: { showCaption: false, captionAlignment: "start" },
    buttons: {
      iconColor: "#6297a7",
      showAutoplayButton: false,
      showDownloadButton: false,
    },
  }

  const myBreakpointsAndCols = { default: 3, 1100: 3, 700: 2, 500: 1 }

  return (
    <>
      <SRLWrapper options={options} images={imageList} />
      <Masonry
        breakpointCols={myBreakpointsAndCols}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((node, i) => (
          <div
            key={node.id}
            id={node.id}
            style={{ cursor: "pointer", border: "none" }}
            onClick={() => openLightbox(i)}
          >
            <GatsbyImage
              loading="auto"
              image={getImage(node.image)}
              alt="Anne Ewers"
            />
          </div>
        ))}
      </Masonry>
    </>
  )
}
